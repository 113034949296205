var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.youtubeId || _vm.link)?_c('div',{staticClass:"b_video section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_title text_center"},[(_vm.content.videoTitle)?_c('h3',[_vm._v(_vm._s(_vm.content.videoTitle))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"video_block"},[_c('div',{staticClass:"video jsVideoWrapper"},[(_vm.youtubeId)?_c('div',{staticClass:"video_wrapper youtube"},[_c('youtube',{ref:"youtube",attrs:{"video-id":_vm.youtubeId}})],1):_c('div',{staticClass:"video_wrapper",class:{
                        playing: _vm.isVideoPlaying
                    }},[_c('button',{staticClass:"btn_play jsBtnVideoPlay",class:{
                            btn_play_pause: _vm.isVideoPlaying
                        },attrs:{"type":"button"},on:{"click":_vm.toggleVideo}}),_vm._v(" "),_c('video',{ref:"video",staticClass:"jsVideo",class:{
                            playing: _vm.isVideoPlaying
                        }},[_c('source',{attrs:{"src":_vm.link,"type":"video/mp4"}})])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }