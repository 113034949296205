<template>
    <div class="b_categories">
        <div class="container">
            <div class="categories">
                <div class="category_list">
                    <div
                        v-for="(button, index) in content.buttons"
                        :key="index"
                        class="category_row"
                    >
                        <nuxt-link
                            class="category_wrap"
                            :to="localePath(button.link)"
                        >
                            <div class="image_wrapper">
                                <div class="image">
                                    <picture v-full-image-path="button.icon" />
                                </div>
                            </div>
                            <div class="title">
                                <h3>{{ button.title }}</h3>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true
        }
    }
};
</script>
