<template>
    <div v-if="!isHidden" class="b_articles section">
        <div class="container">
            <div class="section_title">
                <h3>
                    {{ content.title || content.moreInformation?.title }}
                </h3>
            </div>
            <div class="article_wrapper">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="article_card"
                >
                    <div class="article_row">
                        <article class="card_block">
                            <a
                                class="card_head"
                                @click="onLinkClick(item.information.link)"
                            >
                                <div class="article_image">
                                    <picture
                                        v-if="item.information?.featuredImage"
                                        v-full-image-path="
                                            item.information.featuredImage
                                        "
                                    />
                                    <div v-else class="no_image">
                                        <picture
                                            v-full-image-path:assets="
                                                'article_image_no_image.svg'
                                            "
                                        />
                                    </div>
                                </div>

                                <div
                                    v-if="item.information"
                                    class="article_description"
                                >
                                    <h3>{{ item.information.text }}</h3>
                                    <p v-if="item.information.description">
                                        {{ item.information.description }}
                                    </p>
                                </div>
                                <div
                                    v-else-if="item.text"
                                    class="article_description"
                                >
                                    <h3>{{ item.text }}</h3>
                                    <p v-if="item.description">
                                        {{ item.description }}
                                    </p>
                                </div>
                            </a>

                            <div class="more_wrap self_end">
                                <a
                                    class="link_arrow"
                                    @click="onLinkClick(item.information.link)"
                                >
                                    {{
                                        $t('homepage.buttonsSection.learnMore')
                                    }}
                                </a>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import isExternalLink from '@/helpers/isExternalLink';

export default {
    props: {
        content: {
            type: Object,
            required: true
        },

        count: {
            type: Number,
            default: 4
        },

        hideAllowed: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            items: []
        };
    },

    async fetch() {
        let items = this.content.items || this.content.moreInformation.items;

        const contentItems = await this.getInformationByContentId(
            this.content.id
        );

        if (contentItems.length) {
            items = [...contentItems];
        }

        const missingArticlesCount = this.count - items.length;
        const existingArticlesIds = items.map(item => item.id);

        if (missingArticlesCount) {
            const missingArticles = await this.getRandomArticles({
                count: missingArticlesCount,
                excludedIds: existingArticlesIds
            });

            const mappedMissingArticles = missingArticles.map(article => {
                return {
                    id: article.id,
                    text: article.name,
                    description: article.description,
                    featuredImage: article.featuredImage,
                    link: `/articles/${
                        article.slug || article.numericId || article.id
                    }`
                };
            });

            items.push(...mappedMissingArticles);
        }

        this.items = items;
    },

    computed: {
        isHidden() {
            return this.hideAllowed && this.content?.isHidden;
        }
    },

    methods: {
        ...mapActions({
            getRandomArticles: 'articles/random',
            getInformationByContentId: 'contents/getInformationByContentId'
        }),

        onLinkClick(link) {
            if (isExternalLink(link)) {
                window.open(link, '_blank');
                window.opener = null;

                return;
            }

            this.$router.push(this.localePath(link));
        }
    }
};
</script>
