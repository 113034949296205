import { cdn, mediaUrl } from '@/config';

export default media => {
    if (!media) {
        return;
    }

    const { type, date, filename, extension } = media;

    return `${cdn || mediaUrl}/${type}s/${date}/${filename}.${extension}`;
};
