import { appUrl } from '@/config';

export default ({ languageCode = 'he', hideContext = false } = {}) => {
    const languageUrl = languageCode === 'he' ? '' : `/${languageCode}`;

    return {
        ...(!hideContext && { '@context': 'https://schema.org' }),
        '@type': 'Organization',
        address: {
            '@type': 'PostalAddress',
            streetAddress: '7 Revivim Street',
            postOfficeBoxNumber: '437',
            postalCode: 'IL-5310302',
            addressLocality: 'Givatayim',
            addressCountry: 'IL'
        },
        email: 'info@cancer.org.il',
        legalName: 'Israel Cancer Association',
        name: 'Cancer.org.il',
        telephone: '1-800-599-995',
        sameAs: [
            'https://www.youtube.com/user/cancerisrael',
            'https://x.com/cancer_org_il?s=21&t=9RY_q_0sD6oLfNPaGP2KRg',
            'https://www.instagram.com/israel_cancer_association',
            'https://www.facebook.com/cancer.org.il'
        ],
        url: `${appUrl}${languageUrl}`
    };
};
