import { appUrl } from '@/config';
import getMediaUrl from '@/helpers/getMediaUrl';

export default {
    head() {
        const defaultTitle = 'Cancer.org.il';
        const defaultDescription = 'Cancer.org.il';
        const ogTags = [
            {
                hid: 'og:url',
                property: 'og:url',
                content: appUrl + this.$route.fullPath
            }
        ];
        const link = [];

        if (!this.meta?.canonicalUrl) {
            const { path } = this.$route;

            const canonicalUrl = path === '/' ? appUrl : appUrl + path;

            link.push({ rel: 'canonical', href: canonicalUrl });
        } else {
            link.push({ rel: 'canonical', href: this.meta.canonicalUrl });
        }

        if (!this.meta) {
            return {
                title: defaultTitle,
                meta: [
                    {
                        hid: 'description',
                        name: 'description',
                        content: defaultDescription
                    },
                    ...ogTags
                ],
                link
            };
        }

        this.meta.ogTitle &&
            ogTags.push({
                hid: 'og:title',
                property: 'og:title',
                content: this.meta.ogTitle
            });

        this.meta.ogDescription &&
            ogTags.push({
                hid: 'og:description',
                property: 'og:description',
                content: this.meta.ogDescription
            });

        if (this.meta.ogImage && Object.keys(this.meta.ogImage).length) {
            ogTags.push({
                hid: 'og:image',
                property: 'og:image',
                content: getMediaUrl(this.meta.ogImage)
            });
        }

        this.meta.ogType &&
            ogTags.push({
                hid: 'og:type',
                property: 'og:type',
                content: this.meta.ogType
            });

        const {
            title,
            description,
            noFollow,
            noIndex,
            custom = {}
        } = this.meta;

        return {
            title: title || defaultTitle,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: description || defaultDescription
                },
                {
                    hid: 'robots-follow',
                    name: 'robots',
                    content: noFollow ? 'nofollow' : 'follow'
                },
                {
                    hid: 'robots-index',
                    name: 'robots',
                    content: noIndex ? 'noindex' : 'index'
                },
                ...ogTags
            ],
            link,
            ...custom
        };
    }
};
