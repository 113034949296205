<template>
    <div>
        <hero-banner :content="content.hero" />

        <div class="b_search_form">
            <div class="container">
                <search search-on-input />
            </div>
        </div>

        <first-section :content="content.firstSection" />

        <more-information :content="content" />

        <numbers />

        <volunteer />

        <video-section
            v-if="content.videoSection.isVisible"
            :content="content.videoSection"
        />
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { StatusCodes } from 'http-status-codes';
import headMixin from '@/mixins/head.mixin.js';
import { unsubscribedQueryKey } from '@/config';
import Search from '@/components/common/Search';
import Numbers from '@/components/homepage/Numbers';
import Volunteer from '@/components/common/Volunteer';
import getWebSite from '@/helpers/schemaOrg/getWebSite';
import HeroBanner from '@/components/homepage/HeroBanner';
import VideoSection from '@/components/elements/VideoSection';
import FirstSection from '@/components/homepage/FirstSection';
import getOrganization from '@/helpers/schemaOrg/getOrganization';
import MoreInformation from '@/components/elements/MoreInformation';

export default {
    components: {
        Search,
        Numbers,
        Volunteer,
        HeroBanner,
        VideoSection,
        FirstSection,
        MoreInformation
    },

    mixins: [headMixin],

    async asyncData({ store: { dispatch }, error }) {
        try {
            const { content, meta } = await dispatch(
                'contents/show',
                'homepage'
            );

            return { content, meta };
        } catch (err) {
            if (err.response?.status !== StatusCodes.INTERNAL_SERVER_ERROR) {
                return error({ statusCode: err.response.status });
            }

            console.error(err);

            return error({ statusCode: StatusCodes.INTERNAL_SERVER_ERROR });
        }
    },

    jsonld() {
        const options = {
            languageCode: this.$i18n.locale,
            hideContext: true
        };

        return {
            '@context': 'http://schema.org',
            '@graph': [getOrganization(options), getWebSite(options)]
        };
    },

    mounted() {
        const { query } = this.$route;

        if (Object.prototype.hasOwnProperty.call(query, unsubscribedQueryKey)) {
            this.handleNewsletterUnsubscribeEvent(query);
        }
    },

    methods: {
        handleNewsletterUnsubscribeEvent(query) {
            const clonedQuery = cloneDeep(query);

            const unsubscribedQueryParamValue =
                clonedQuery[unsubscribedQueryKey];

            if (unsubscribedQueryParamValue === 'ok') {
                this.$toasted.success(this.$t('newsletter.signOutSuccess'));
            } else {
                this.$toasted.error(this.$t('newsletter.signOutFailure'));
            }

            delete clonedQuery[`${unsubscribedQueryKey}`];

            this.$router.replace({ query: clonedQuery });
        }
    }
};
</script>
