<template>
    <div class="search_form">
        <form ref="searchForm" @submit.prevent="onSubmit">
            <div class="form">
                <input
                    id="search"
                    v-model="search"
                    name="search"
                    class="form_control"
                    type="text"
                    :placeholder="$t('homepage.searchPlaceholder')"
                    @input="onSearchInput"
                />
                <button class="btn btn_gray btn_reset btn_search" type="submit">
                    {{ $t('homepage.searchButton') }}
                </button>
            </div>
        </form>

        <search-results-desktop v-if="showSearchResults" :results="results" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import SearchResultsDesktop from '@/components/layout/header-items/SearchResultsDesktop';

export default {
    components: {
        SearchResultsDesktop
    },

    props: {
        searchOnInput: {
            type: Boolean,
            required: false,
            default: false
        },

        limit: {
            type: Object,
            default: () => null
        }
    },

    data() {
        return {
            search: '',
            results: {},
            showSearchResults: false
        };
    },

    methods: {
        ...mapActions({
            fetchSearchedItems: 'search/index'
        }),

        onSubmit() {
            if (!this.search) {
                return;
            }

            this.$router.push(this.localePath(`/search?q=${this.search}`));
            this.$refs.searchForm.reset();
        },

        async handleSearch() {
            if (!this.search) {
                return;
            }

            try {
                const params = {
                    q: this.search
                };

                if (this.limit) {
                    params.limit = this.limit;
                }

                this.results = await this.fetchSearchedItems(params);
            } catch (error) {
                console.error(error);

                this.$toasted.global.error();
            }
        },

        onSearchInput() {
            if (!this.searchOnInput) {
                return;
            }

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            if (!this.search) {
                this.showSearchResults = false;

                return;
            }

            this.timer = setTimeout(() => {
                this.handleSearch();

                this.showSearchResults = true;
            }, 500);
        }
    }
};
</script>
