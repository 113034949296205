import { appUrl } from '@/config';

export default ({ languageCode = 'he', hideContext = false } = {}) => {
    const languageUrl = languageCode === 'he' ? '' : `/${languageCode}`;
    const url = `${appUrl}${languageUrl}`;

    return {
        ...(!hideContext && { '@context': 'https://schema.org' }),
        '@type': 'WebSite',
        url,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate: `${url}/search?q={search_term_string}`
            },
            'query-input': 'required name=search_term_string'
        }
    };
};
