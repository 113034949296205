<template>
    <div v-if="youtubeId || link" class="b_video section">
        <div class="container">
            <div class="section_title text_center">
                <h3 v-if="content.videoTitle">{{ content.videoTitle }}</h3>
            </div>

            <div class="video_block">
                <div class="video jsVideoWrapper">
                    <div v-if="youtubeId" class="video_wrapper youtube">
                        <youtube ref="youtube" :video-id="youtubeId" />
                    </div>

                    <div
                        v-else
                        class="video_wrapper"
                        :class="{
                            playing: isVideoPlaying
                        }"
                    >
                        <button
                            class="btn_play jsBtnVideoPlay"
                            :class="{
                                btn_play_pause: isVideoPlaying
                            }"
                            type="button"
                            @click="toggleVideo"
                        />

                        <video
                            ref="video"
                            class="jsVideo"
                            :class="{
                                playing: isVideoPlaying
                            }"
                        >
                            <source :src="link" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Youtube, getIdFromUrl } from 'vue-youtube';

import getMediaUrl from '@/helpers/getMediaUrl';

export default {
    components: {
        Youtube
    },

    props: {
        content: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isVideoPlaying: false
        };
    },

    computed: {
        youtubeId() {
            if (!this.content.youtube) {
                return null;
            }

            return getIdFromUrl(this.content.youtube);
        },

        link() {
            return this.youtubeId ? null : getMediaUrl(this.content.video);
        }
    },

    methods: {
        toggleVideo() {
            const video = this.$refs.video;

            if (this.isVideoPlaying) {
                this.isVideoPlaying = false;

                video.pause();
            } else {
                this.isVideoPlaying = true;

                video.play();
            }

            video.addEventListener('play', () => {
                this.isVideoPlaying = true;
            });

            video.addEventListener('pause', () => {
                this.isVideoPlaying = false;
            });
        }
    }
};
</script>
